require("styles/fonts.css");
require("destyle.css/destyle.css");
require("pure-react-carousel/dist/react-carousel.es.css");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");

exports.onClientEntry = () => {
  // FIX IE11
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement("img");

  if (typeof testImg.style.objectFit === "undefined" || typeof testImg.style.objectPosition === "undefined") {
    require("object-fit-images")();
    console.log("👍 Object-fit/Object-position are polyfilled");
  }

  // FIX IE11
  // Fetch polyfill
  if (!window.fetch) {
    require("whatwg-fetch");
    console.log("👍 window.fetch is polyfilled");
  }
};
