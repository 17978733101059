// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-es-404-tsx": () => import("./../src/pages/es/404.tsx" /* webpackChunkName: "component---src-pages-es-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */)
}

