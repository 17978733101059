module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W5CCMVC"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TZ4B4K7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Montefiore","short_name":"Montefiore","start_url":"/","background_color":"#003580","theme_color":"#003580","display":"standalone","icon":"src/assets/images/icon.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"572d329fa2aa8063d5a27e2409393751"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
